import { AddCircle } from "@mui/icons-material";
import {
  Grid,
  Paper,
  Box,
  Divider,
  Stack,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ActionButtonSmall from "../../components/layout/buttons/action-button-small";
import { CountriesContext } from "../../context/countries";
import { TranslationsContext } from "../../context/translations";
import PasswordResetLinkField from "./PasswordResetLinkField";
import ConfirmationDialogActivator from "../../components/confirmation-dialog/confirmation-dialog-activator";
import UpdateUserEmail from "../users/user/UpdateUserEmail";
// import ReactCrop from "react-image-crop";

const PersonalSettings = ({
  account,
  handleAvatarChange,
  avatarUrl,
  formIsValid,
  handleSaveProfile,
  emailIsValid,
  handleEdit,
  firstNameIsValid,
  lastNameIsValid,
  admin = false,
  
}) => {
  const [avatarMouseOver, setAvatarMouseOver] = React.useState(false);
  const [inputKey, setInputKey] = useState(Date.now());

  const { countries } = useContext(CountriesContext);

  const { translations } = useContext(TranslationsContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // last login
  const isoDateStringLastLogin = account?.last_login;
  const dateLastLogin = new Date(isoDateStringLastLogin);

  const dayLastLogin = String(dateLastLogin.getDate()).padStart(2, "0");
  const monthLastLogin = String(dateLastLogin.getMonth() + 1).padStart(2, "0");
  const yearLastLogin = dateLastLogin.getFullYear();
  const hoursLastLogin = String(dateLastLogin.getHours()).padStart(2, "0");
  const minutesLastLogin = String(dateLastLogin.getMinutes()).padStart(2, "0");

  const readableDateLastLogin = `${dayLastLogin}/${monthLastLogin}/${yearLastLogin}, ${hoursLastLogin}:${minutesLastLogin}`;

  // created at
  const isoDateStringCreatedAt = account?.created_at;
  const dateCreatedAt = new Date(isoDateStringCreatedAt);

  const dayCreatedAt = String(dateCreatedAt.getDate()).padStart(2, "0");
  const monthCreatedAt = String(dateCreatedAt.getMonth() + 1).padStart(2, "0");
  const yearCreatedAt = dateCreatedAt.getFullYear();
  const hoursCreatedAt = String(dateCreatedAt.getHours()).padStart(2, "0");
  const minutesCreatedAt = String(dateCreatedAt.getMinutes()).padStart(2, "0");

  const readableDateCreatedAt = `${dayCreatedAt}/${monthCreatedAt}/${yearCreatedAt}, ${hoursCreatedAt}:${minutesCreatedAt}`;

  const handleChangeAvatar = (e) => {
    setInputKey(Date.now());
    handleAvatarChange(e);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Grid item xs={12} md={12}>
      <Paper elevation={3}>
        <Box paddingX={2} paddingBottom={2}>
          <Grid container>
            <Grid item xs={12} md={12} paddingBottom={3}>
              <h1>
                {translations["heading.personal_settings"] ??
                  "Personal Settings"}
              </h1>
              <Divider />
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack
                spacing={2}
                height={"100%"}
                justifyContent={"space-between"}
              >
                {admin ? (
                  <img
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                    }}
                    src={avatarUrl ? avatarUrl : "/placeholder.png"}
                  />
                ) : (
                  <Stack
                    sx={{
                      opacity: avatarMouseOver ? 0.6 : 1,
                      // cursor: "pointer",
                    }}
                  >
                    <input
                      type="file"
                      onChange={handleChangeAvatar}
                      id="upload"
                      accept="image/*"
                      style={{ display: "none" }}
                      key={inputKey}
                    />
                    <label
                      htmlFor="upload"
                      style={{
                        position: "relative",
                        width: "200px",
                        height: "200px",
                      }}
                    >
                      <img
                        style={{
                          width: "200px",
                          height: "200px",
                          cursor: "pointer",
                          objectFit: "cover",
                        }}
                        src={avatarUrl ? avatarUrl : "/placeholder.png"}
                        onMouseOver={() => setAvatarMouseOver(true)}
                        onMouseLeave={() => setAvatarMouseOver(false)}
                      />
                      {avatarMouseOver ? (
                        <IconButton
                          aria-label="edit"
                          sx={{
                            pointerEvents: "none",
                            position: "absolute",
                            top: "35%",
                            left: "35%",
                          }}
                          size="large"
                        >
                          <AddCircle color="action" fontSize="large" />
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </label>
                    {/* </Box> */}
                  </Stack>
                )}

                <Stack direction="row" paddingY={2}>
                  <ActionButtonSmall
                    disabled={!formIsValid}
                    name="save"
                    value="personal-settings"
                    sx={{ backgroundColor: "#667080" }}
                    onClick={() =>
                      handleSaveProfile(
                        translations[
                          "flash_message.personal_settings_updated"
                        ] ?? "Personal settings updated successfully."
                      )
                    }
                  >
                    {translations["button.save"] ?? "Save"}
                  </ActionButtonSmall>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={8}
              spacing={2}
              height={"fit-content"}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  error={!emailIsValid}
                  fullWidth
                  label={translations["label.email"] ?? "Email"}
                  name="email"
                  value={account?.email}
                  onChange={handleEdit}
                  disabled
                  inputProps={{ autoCapitalize: "none" }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>
                    {translations["label.country"] ?? "Country"}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={translations["label.country"] ?? "Country"}
                    name="country"
                    value={account?.user_profile?.country?.id}
                    onChange={handleEdit}
                  >
                    {countries?.map((country) => (
                      <MenuItem value={country?.id} key={country?.id}>
                        {country?.country}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {admin && (
                <Grid item xs={12} md={12}>
                  <ActionButtonSmall onClick={handleOpenModal}>
                  {translations["button.change_email"] ?? "Change Email"}
                  </ActionButtonSmall>
                  <UpdateUserEmail
                    currentEmail={account.email}
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                    />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <TextField
                  error={!firstNameIsValid}
                  fullWidth
                  label={translations["label.first_name"] ?? "First Name"}
                  name="firstName"
                  value={account?.user_profile?.first_name}
                  onChange={handleEdit}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  error={!lastNameIsValid}
                  fullWidth
                  label={translations["label.last_name"] ?? "Last Name"}
                  name="lastName"
                  value={account?.user_profile?.last_name}
                  onChange={handleEdit}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  disabled
                  label={translations["label.last_activity"] ?? "Last Activity"}
                  name="lastActivity"
                  value={readableDateLastLogin}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  disabled
                  label={translations["label.first_signup"] ?? "First Signup"}
                  value={readableDateCreatedAt}
                />
              </Grid>
              {admin && (
                <Grid item xs={12}>
                  <Typography>
                    {translations["label.reset_password"] ??
                      "Here is a password reset link where you can reset your password:"}
                  </Typography>
                  {<PasswordResetLinkField account={account} />}
                </Grid>
              )}

              <Grid item xs={12} md={12}>
                <Typography color={"#9E9E9E"} fontSize="12px">
                  {translations["help.first_signup"] ??
                    "First Signup is date and time of user's signup and user's acceptance of the following acknowledgements:"}
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked
                        disabled
                        name="termsOfUse"
                        color="primary"
                        size="small"
                      />
                    }
                    label={
                      <Typography color={"#9E9E9E"} fontSize="12px">
                        {translations["label.terms_of_use"] ?? "Terms of Use"}
                        {" & "}
                        {translations["label.privacy_policy"] ??
                          "Privacy Policy"}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked
                        disabled
                        name="marketing"
                        color="primary"
                        size="small"
                      />
                    }
                    label={
                      <Typography color={"#9E9E9E"} fontSize="12px">
                        {translations["label.marketing_permissions"] ??
                          "Marketing Permissions"}
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export default PersonalSettings;
