import React, { useState, useContext } from "react";
import {
  Grid,
  Paper,
  Box,
  TextField,
  Typography,
  Stack,
  Button,
  Modal,
} from "@mui/material";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import { TranslationsContext } from "../../../context/translations";
import { AuthContext } from "../../../context/auth";
import { LanguagesContext } from "../../../context/languages";
import { getCookie } from "../../../App";

const UpdateUserEmail = ({ open, handleClose, currentEmail }) => {
  const [newEmail, setNewEmail] = useState("");
  const { startGlobalLoader, stopGlobalLoader } = useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } = useContext(SnackbarContext);
  const { translations } = useContext(TranslationsContext);
  const { setUserEmail } = useContext(AuthContext);
  const { jwt } = useContext(AuthContext);
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }; 

  const handleUpdateEmail = (successMessage) => {
    startGlobalLoader();
    const payload = { old_email: currentEmail, new_email: newEmail };
  
    API.put(ApiUri.USER + ApiUri.UPDATE_EMAIL, payload, config).then(
      (response) => {
        const { status, message } = response.data.data;
        if (status === "success") {
          setUserEmail(newEmail);
          if (successMessage) {
            initSnackbarSuccess(message);
          }
        } else {
          initSnackbarError(message);
        }
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error?.response?.data?.message || "An unexpected error occurred.");
        stopGlobalLoader();
      }
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <Grid item xs={11} sm={8} md={6} lg={4}>
          <Paper elevation={3}>
            <Box padding={4}>
              <Typography variant="h6" gutterBottom>
                {translations["heading.update_email_title"] ?? "Update User Email Address"}
              </Typography>
              <TextField
                label={translations["label.current_email"] ?? "Current Email Address"}
                value={currentEmail}
                disabled
                fullWidth
                margin="normal"
              />
              <TextField
                label={translations["label.new_email"] ?? "New Email Address"}
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                fullWidth
                margin="normal"
              />
              <Stack direction="row" justifyContent="flex-end" spacing={2} marginTop={2}>
                <Button variant="contained" color="primary" onClick={handleUpdateEmail}>
                  {translations["button.update_email_confirmation"] ?? "Comfirm Email Change"}
                </Button>
                <Button variant="outlined" onClick={handleClose}>
                  {translations["button.cancel"] ?? "Cancel"}
                </Button>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default UpdateUserEmail;