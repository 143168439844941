import React, { createContext, useContext, useEffect, useState } from "react";
import API from "../api/api";
import ApiUri from "../api/api-uri";
import { User } from "../models/user/user";
import * as SnackBar from "node-snackbar";
import "node-snackbar/dist/snackbar.css";
import { GlobalLoaderContext } from "./global-loader";
import { CircularProgress } from "@mui/material";
import Colors from "../enums/colors";
import { setCookie } from "../App";

export interface IAuthContext {
  user?: User;
  setUser?: any;
  jwt?: string;
  setJwt?: any;
  userImageUrl?: string;
  setUserImageUrl?: any;
  profileNameLogin: string;
  setProfileNameLogin: any;
  profileImageURLLogin: string;
  setProfileImageURLLogin: any;
  profileUrlName: any;
  setProfileUrlName: any;
  showSignup: any;
  setShowSignup: any;
  userEmail?: string;
  setUserEmail?: any;
}

export const AuthContext = createContext({} as IAuthContext);

export const getJwtFromCookies = () => {
  const value = `; ${document.cookie}`;
  const parts: any = value.split(`; ${process.env.REACT_APP_MCZTOKEN_COOKIE}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const AuthContextProvider = ({ children }: any) => {
  const jwtFromCookies = getJwtFromCookies();
  const [jwt, setJwt] = useState(jwtFromCookies ?? "");
  const [user, setUser] = useState<any>(undefined);
  const [userImageUrl, setUserImageUrl] = useState<string>("");
  const [profileNameLogin, setProfileNameLogin] = useState("");
  const [profileImageURLLogin, setProfileImageURLLogin] = useState("");
  const [loader, setLoader] = useState(false);
  const [profileUrlName, setProfileUrlName] = useState("");
  const [showSignup, setShowSignup] = useState(true);
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    if (!user && jwt) {
      setLoader(true);
      // SnackBar.show({
      //   showAction: false,
      //   text: "Please wait, loading application ...",
      //   pos: "bottom-center",
      //   duration: 15000,
      // });

      const config = {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      };
      API.get(ApiUri.MY_ACCOUNT, config).then(
        (response) => {
          const userFromEndpoint = response.data.data;
          API.get(ApiUri.USER + ApiUri.IMAGE, config).then((response) => {
            const imageUrl = response.data.data.image;
            setUserImageUrl(imageUrl);
            setUser(userFromEndpoint);
            setUserEmail(userFromEndpoint.email);
            setLoader(false);
          });
        },
        (error) => {
          setCookie(process.env.REACT_APP_MCZTOKEN_COOKIE, "", 14);
        }
      );
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        jwt,
        setJwt,
        userImageUrl,
        setUserImageUrl,
        profileNameLogin,
        setProfileNameLogin,
        profileImageURLLogin,
        setProfileImageURLLogin,
        profileUrlName,
        setProfileUrlName,
        showSignup,
        setShowSignup,
        userEmail,
        setUserEmail,
      }}
    >
      {loader ? (
        <CircularProgress
          size={100}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            zIndex: 9999999,
            color: Colors.primary,
            marginLeft: -50,
            marginTop: -50,
          }}
        />
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
